
import React from "react";
import experiences from './../data/experienceData.json';
import './../css/Experience.css';

export default function ExperienceItem() {
    return (
        <div className="experience-container">
          {experiences.map((exp, index) => (
            <div key={index} className="experience-item">
              <div className="experience-left">
                <img src={require(`../images/${exp.image}`)} alt={exp.company} />
                <h2>{exp.position}</h2>
                <h3>{exp.company}</h3>
                <p>{exp.years}</p>
              </div>
              <div className="experience-right">
                <ul>
                  {exp.description.map((desc, i) => (
                    <li key={i}>{desc}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      );
}
